import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ArchiveTemplate = ({
  title,
  helmet,
  categories
}) => {
  return (
    <section className="archive-container">
      {helmet || ''}
      <div>
        {categories.map(category => {
          return (
            <div className="archive-category-container" key={category.title}>
              <h2 className="archive-category-title">{category.title}</h2>
              <ul className="archive-category-list">
                {category.links.map(link => {
                  return (
                    <li key={link.title}>
                      <a href={link.link} target="_blank" rel="noopener noreferrer">{link.title} by {link.author}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </section>
  )
}

ArchiveTemplate.propTypes = {
  categories: PropTypes.array.isRequired,
  title: PropTypes.string,
  helmet: PropTypes.object
}

const Archive = ({ data }) => {
  const cats = data.markdownRemark.frontmatter.categories
  return (
    <Layout activePage="archive">
      <ArchiveTemplate
        helmet={
          <Helmet titleTemplate="%s | Archive">
            <title>{'SM28 | Archive'}</title>
            {/* <meta
              name="description"
              content={'TODO: Meta description for Archive goes here'}
            /> */}
          </Helmet>
        }
        categories={cats}
        title='Archive'
      />
    </Layout>
  )
}

Archive.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default Archive

export const pageQuery = graphql`
  query ArchivePage {
    markdownRemark(frontmatter: { templateKey: { eq: "archive" } }) {
        frontmatter{
            categories {
                title
                links {
                  title
                  author
                  link
                }
            }
        }
    }
  }
`
